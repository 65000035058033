import Page from "../../components/page"
import SEO from "../../components/seo"
import React from "react"
import Reference, {
  InitialSituationHeading,
  SolutionApproachHeading,
} from "../../components/reference"
import CustomFormattedMessage from "../../components/custom-formatted-message"
import Brain from "../../components/brain"
import { graphql, useStaticQuery } from "gatsby"
import { useIntl } from "gatsby-plugin-intl"
import { GatsbyImage, getImage, getSrc } from "gatsby-plugin-image"

const MicroFrontendsPage = ({ location }: { location: Location }) => {
  const { formatMessage } = useIntl()

  const id = "microfrontends"
  const { microfrontendsImg } = useStaticQuery(
    graphql`
      query {
        microfrontendsImg: file(
          relativePath: { eq: "references/microfrontends.png" }
        ) {
          ...DefaultImageOptions
        }
      }
    `
  )

  return (
    <Page>
      <SEO
        title={formatMessage({ id: `reference.${id}.title` })}
        description={formatMessage({ id: `reference.${id}.seoDescription` })}
        ogImage={getSrc(microfrontendsImg)}
        location={location}
      />
      <Reference id={id}>
        <InitialSituationHeading />
        <p>
          <CustomFormattedMessage id={`reference.${id}.initial-situation`} />
        </p>
        <SolutionApproachHeading />
        <Brain
          leftContent={
            <CustomFormattedMessage id={`reference.${id}.brain.left-content`} />
          }
          rightContent={
            <CustomFormattedMessage
              id={`reference.${id}.brain.right-content`}
            />
          }
        />
        <p>
          <CustomFormattedMessage id={`reference.${id}.solution-approach`} />
        </p>
        <GatsbyImage image={getImage(microfrontendsImg)!} alt="" />
      </Reference>
    </Page>
  )
}

export default MicroFrontendsPage
